import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Creators as ModalCreators } from "../../store/ducks/modalVinculo";
import { Creators as ModalActionCreators } from "../../store/ducks/modalAction";

import { Container } from "./styles";
import Toggle from "../Toggle";
import Profile from "../../assets/img/sistema/profile.svg";
import Sino from "../../assets/img/sistema/sino.svg";
import link from "../../assets/img/sistema/Interface.svg";
import { maskCpfCnpj } from "../../utils/helper";

function Vinculo(props) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const {
    tipo,
    titleBtn = "Vincular",
    toggle,
    approved = false,
    id,
    status_vinculo,
    dados,
    acaoType,
  } = props;
  const tipoAcao = dados?.acao?.tipo_atual
    ? dados?.acao?.tipo_atual
    : dados?.acao?.type;
  const { nome_vendedor, nome_gerente, status } = dados;

  const documento = maskCpfCnpj(props?.dados?.vendedor?.documento || "");

  function click(dados) {
    dispatch(ModalCreators.clear_dados());
    dispatch(ModalCreators.modal_vinculo_vendedor());
    dispatch(ModalCreators.set_id_modal(dados));
  }

  function handleChange(status) {
    var sendStatus = 2;
    const check = dados?.nome_gerente && dados?.nome_vendedor ? true : false;
    if (status) {
      sendStatus = 1;
    }

    const config = {
      itens: [id],
      sendStatus,
      sem_vinculo: check,
      acaoType,
    };

    dispatch(ModalActionCreators.open(config));
  }

  function removerHandleChange() {
    var sendStatus = 2;
    const check = dados?.nome_gerente && dados?.nome_vendedor ? true : false;

    const config = {
      itens: [id],
      sendStatus,
      sem_vinculo: check,
      acaoType,
    };

    dispatch(ModalActionCreators.openModalRemoverAprovacao(config));
  }

  const statusTipo = status.id === 1 ? true : false;

  const classStatus = useMemo(() => {
    let result = "";
    if (status.id === 1) {
      result = "aprovado";
    } else if (status.id === 2) {
      result = "aguardando";
    } else {
      result = "vinculo";
    }
    return result;
  }, [status]);

  const arrStatusDisabled = [1, 2];

  const disabledAlterarVinculo =
    props?.dados?.user_id !== user.id &&
    arrStatusDisabled.includes(props?.dados.status.id) &&
    tipoAcao !== "financiamento_valor_fixo";

  const nomeGerente = nome_gerente ? nome_gerente : dados?.gerente?.nome;

  console.log(tipoAcao);

  return (
    <Container
      className={`${classStatus} ${
        classStatus === "aprovado" ? "btn-olho" : ""
      }`}
    >
      <div className="tipo">
        <img src={Profile} alt="" />
        {dados?.gestor?.id ? (
          <>
            {[
              "financiamento",
              "financiamento_valor",
              "financiamento_valor_fixo",
            ].includes(tipoAcao) ? (
              <span>
                Vendedor:{" "}
                <div className="container-detalhes">
                  <strong className="documento-vendedor">{documento} </strong>
                  <strong>
                    {nome_vendedor}{" "}
                    {dados?.vendedor_valor ? `- ${dados?.vendedor_valor}` : ""}
                  </strong>
                </div>
              </span>
            ) : (
              <span>
                Gestor: <strong>{dados.gestor.nome} </strong>
              </span>
            )}
          </>
        ) : (
          <span>
            Vendedor:{" "}
            <div className="container-detalhes">
              <strong className="documento-vendedor">
                {documento ? `${documento} ` : ""}
              </strong>
              <strong>
                {nome_vendedor}{" "}
                {dados?.vendedor_valor ? `- ${dados?.vendedor_valor}` : ""}
              </strong>
            </div>
          </span>
        )}
      </div>
      <div className="tipo">
        {dados?.gestor?.id ? <></> : <img src={Profile} alt="" />}

        {dados?.gerente?.id ? (
          <>
            {["financiamento", "financiamento_valor"].includes(tipoAcao) ? (
              <span>
                Gerente / F&I :{" "}
                <div className="container-detalhes">
                  <strong>
                    <strong className="documento-vendedor">
                      {dados?.gerente?.documento
                        ? dados?.gerente?.documento
                        : ""}
                    </strong>
                    <strong>
                      {nomeGerente}
                      {dados?.gerente_valor ? `- ${dados?.gerente_valor}` : ""}
                    </strong>
                  </strong>
                </div>
              </span>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
      </div>
      <div className="tipo">
        <img src={Sino} alt="" />
        <span>
          Status:{" "}
          <strong>
            {status.status.charAt(0) + status.status.slice(1).toLowerCase()}
          </strong>
        </span>
      </div>

      {acaoType !== "cartao_mit" && approved && toggle ? (
        <div className="container-toogles">
          {[
            "financiamento",
            "financiamento_valor",
            "financiamento_valor_fixo",
          ].includes(tipoAcao) && props?.dados.status.id !== 3 ? (
            <Toggle
              active={statusTipo}
              disabled={statusTipo}
              change={handleChange}
              teste={tipo.class}
            />
          ) : (
            <></>
          )}

          {["financiamento", "financiamento_valor"].includes(tipoAcao) &&
          props?.dados.status.id !== 3 ? (
            <button
              className="remover"
              disabled={statusTipo}
              onClick={removerHandleChange}
            >
              Remover Vínculo
            </button>
          ) : (
            <></>
          )}
        </div>
      ) : acaoType !== "cartao_mit" &&
        !approved &&
        toggle &&
        status_vinculo.id !== 1 ? (
        <div className="vinculo">
          <button
            onClick={() => click({ id, status_vinculo, ...dados })}
            disabled={disabledAlterarVinculo}
          >
            {" "}
            <img src={link} alt="" /> {titleBtn}
          </button>
          {tipoAcao === "financiamento_valor_fixo" &&
            props?.dados.status.id === 2 && (
              <button
                className="aprovar-vinculo"
                disabled={!!props?.dados?.has_updated || disabledAlterarVinculo}
                onClick={() => {
                  dispatch(ModalCreators.set_id_modal(dados));
                  dispatch(ModalCreators.modal_aprovar_vinculo());
                }}
              >
                Confirmar Vínculo
              </button>
            )}

          {["financiamento", "financiamento_valor"].includes(tipoAcao) &&
          props?.dados.status.id !== 3 ? (
            <button
              className="remover"
              style={{ marginLeft: 16 }}
              disabled={statusTipo || disabledAlterarVinculo}
              onClick={removerHandleChange}
            >
              Remover Vínculo
            </button>
          ) : (
            <></>
          )}
        </div>
      ) : null}
    </Container>
  );
}

export default Vinculo;
